import React from "react";
import resumeEnPath from "../../assets/files/resume_en.pdf";
import resumeTrPath from "../../assets/files/resume_tr.pdf";

import { Button, ButtonGroup, ButtonOr, Icon } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

export default function ResumeViewer(props) {
  return (
    <div>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <ButtonGroup size="large">
            <Button
              disabled
              color="grey"
              icon
              labelPosition="right"
              size="huge"
            >
              <Icon name="arrow right" />
              {props.btnText}
            </Button>
            <Button
              href={resumeEnPath}
              target="_blank"
              rel="noreferrer"
              size="huge"
              content={"EN"}
              floated={props.floated}
              color={props.color}
            ></Button>
            <ButtonOr />
            <Button
              href={resumeTrPath}
              target="_blank"
              rel="noreferrer"
              size="huge"
              content={"TR"}
              floated={props.floated}
              color={props.color}
            ></Button>
          </ButtonGroup>
        </Media>
        <Media at="mobile">
          <ButtonGroup size="large">
            <Button
              disabled
              color="grey"
              style={{ fontSize: "35px" }}
              size="medium"
              icon
              labelPosition="right"
              className="mt-1"
            >
              <Icon name="arrow right" />
              {props.btnText}
            </Button>
            <Button
              href={resumeEnPath}
              target="_blank"
              rel="noreferrer"
              size="medium"
              content={"EN"}
              floated={props.floated}
              color={props.color}
              style={{ fontSize: "35px" }}
              className="mt-1"
            ></Button>
            <Button
              href={resumeTrPath}
              target="_blank"
              rel="noreferrer"
              size="medium"
              content={"TR"}
              floated={props.floated}
              color={props.color}
              style={{ fontSize: "35px" }}
              className="mt-1"
            ></Button>
          </ButtonGroup>
        </Media>
      </MediaContextProvider>
    </div>
  );
}
