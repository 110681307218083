import React from "react";
import Navi from "./navi/Navi";
import HomePage from "../home/HomePage";
import Footer from "./footer/Footer";
import ScrollButton from "../../components/scroll-button/ScrollButton";

export default function Layout() {
  return (
    <div>
      <Navi>
        <HomePage></HomePage>
      </Navi>
      <ScrollButton></ScrollButton>
      <Footer></Footer>
    </div>
  );
}
