import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { Grid, Header, Image } from "semantic-ui-react";
import ResumeViewer from "../resume/ResumeViewer";
import { images } from "../../constants/Images";
import { links } from "../../constants/Links";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

const ExperienceComponent = forwardRef(function (_, ref) {
  return (
    <div ref={ref}>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                  <Header className="home-header text-start">Experience</Header>
                <p style={{ fontSize: "1.33em", textAlign: "start" }}>
                  I worked at some small companies, actually I haven't got much
                  experience in software companies. I developed 10 projects that
                  are 4 big, 6 normal level projects totally. 4 big projects are
                  pinned in my{" "}
                  <Link
                    as="a"
                    to={links.github}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </Link>{" "}
                  account
                </p>
                <p style={{ fontSize: "1.33em", textAlign: "start" }}>
                  Now, I can develop with <b>ASP.NET, Spring, Angular, React</b>
                  . I know classic and onion architectures (+CQRS) very well. I
                  developed 10 projects with these architectures.
                </p>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                {/* Images */}
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image rounded size="small" src={images["sorulukLogo"]} />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        rounded
                        size="small"
                        src={images["transportathonLogo"]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image rounded size="small" src={images["allentLogo"]} />
                    </Grid.Column>
                    <Grid.Column>
                      <Image rounded size="small" src={images["kitabeLogo"]} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {/* Images */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="left">
                <ResumeViewer
                  color="green"
                  btnText="All experiences "
                ></ResumeViewer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
        <Media at="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row className="mb-2">
              <Grid.Column floated="left">
                {/* Images */}
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image rounded size="huge" src={images["sorulukLogo"]} />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        rounded
                        size="huge"
                        src={images["transportathonLogo"]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image rounded size="huge" src={images["allentLogo"]} />
                    </Grid.Column>
                    <Grid.Column>
                      <Image rounded size="huge" src={images["kitabeLogo"]} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {/* Images */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                  <Header className="home-header text-start">Experience</Header>
                <p
                  style={{
                    fontSize: "3.6em",
                    textAlign: "start",
                    marginTop: "1em",
                  }}
                >
                  I worked at some small companies, actually I haven't got much
                  experience in software companies. I developed 10 projects that
                  are 4 big, 6 normal level projects totally. 4 big projects are
                  pinned in my{" "}
                  <Link
                    as="a"
                    to={links.github}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </Link>{" "}
                  account
                </p>
                <p style={{ fontSize: "3.6em", textAlign: "start" }}>
                  Now, I can develop with <b>ASP.NET, Spring, Angular, React</b>
                  . I know classic and onion architectures (+CQRS) very well. I
                  developed 10 projects with these architectures.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="left">
                <ResumeViewer
                  color="green"
                  btnText="All experiences"
                ></ResumeViewer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
    </div>
  );
});

export default ExperienceComponent;
